import { css } from '@emotion/react'
import { spacing, useScreenType } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'

type SignEndorsementLayoutProps = {
  children: ReactNode
}

export const SignEndorsementLayout = memo<SignEndorsementLayoutProps>(function SignEndorsementLayout(props) {
  const { children } = props
  const screenType = useScreenType()
  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <div
        css={
          screenType === 'mobile'
            ? undefined
            : css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              `
        }
      >
        <div
          css={css`
            margin: ${screenType === 'mobile' ? spacing[60] : spacing[100]};
            display: flex;
            flex-direction: column;
            gap: ${spacing[70]};
          `}
        >
          {children}
        </div>
      </div>
    </div>
  )
})

export const SignEndorsementSuccessLayout = memo<SignEndorsementLayoutProps>(function SignEndorsementLayout(props) {
  const { children } = props
  const screenType = useScreenType()
  return (
    <div>
      <div
        css={
          screenType === 'mobile'
            ? undefined
            : css`
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              `
        }
      >
        <div
          css={css`
            margin: ${screenType === 'mobile' ? spacing[60] : spacing[100]};
            display: flex;
            flex-direction: column;
            align-items: ${screenType === 'mobile' ? undefined : 'center'};
            justify-content: ${screenType === 'mobile' ? undefined : 'center'};
            gap: ${spacing[70]};
          `}
        >
          {children}
        </div>
      </div>
    </div>
  )
})
