import { css } from '@emotion/react'
import { memo } from 'react'
import { spacing } from '../../../../foundation/spacing-tokens.js'
import { Text } from '../../../atoms/index.js'
import { LegacyDialog } from '../../../templates/index.js'

export type EndorsementConfirmDialogProps = {
  onSubmit: () => void
  onClose: () => void
}

export const EndorsementConfirmDialog = memo<EndorsementConfirmDialogProps>(function EndorsementConfirmDialog(props) {
  const { onSubmit, onClose } = props
  return (
    <LegacyDialog
      maxWidth="sm"
      onClose={onClose}
      title={`Finalisation de l'avenant`}
      onSubmit={onSubmit}
      submitLabel="Terminer"
      onSecondaryAction={onClose}
      secondaryActionLabel="Annuler"
    >
      <div
        css={css`
          gap: ${spacing[70]};
        `}
      >
        <div>
          <Text
            variant="body2"
            css={css`
              margin-bottom: ${spacing[60]};
            `}
          >
            Vous êtes sur le point de créer un nouvel avenant. Le contrat sera ainsi modifié avec les nouvelles valeurs
            renseignées.
          </Text>
          <Text variant="body2">
            Dès lors que vous cliquez sur le bouton « Terminer », un email est envoyé au client afin qu’il puisse
            procéder à la signature de son avenant.
          </Text>
        </div>
      </div>
    </LegacyDialog>
  )
})
